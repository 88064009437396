import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import spacetime from 'spacetime';

import Coins from '@/assets/Coins.svg?react';
import DefaultProfileImage from '@/assets/Default_profile_bold.svg';
import MintWizard from '@/assets/Mintwizard.svg?react';
import Sort from '@/assets/Sort.svg?react';
import Team from '@/assets/Team.svg?react';
import SelectAsset from '@/components/modals/select-asset';
import SearchInput from '@/components/ui/search-input';
import { SelectorDropdown } from '@/components/ui/selector-dropdown';
import { useToast } from '@/components/ui/use-toast';
import { formatTimestamp } from '@/const';
import {
  ExportUsersDocument,
  GetCountsDocument,
  Tables,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';
import { Cross1Icon, DownloadIcon } from '@radix-ui/react-icons';

import SectionDivider from '../ui/section-divider';
import Table from '../ui/table';

const Users: FunctionComponent<any> = () => {
  const [searchBy, setSearchBy] = useState('Email');
  const [sortBy, setSortBy] = useState('created_at');
  const [sortDirectionIndex, setSortDirectionIndex] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const [filterSelected, setFilterSelected] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [userCount, setUserCount] = useState<boolean | number>(false);

  const { toast } = useToast();
  const navigate = useNavigate();

  const assets = useBoundStore((state) => state.assetsSlice.assets);

  const {
    searchResults,
    fetchSearchedUsers,
    isLoading,
    dispatchSetSearchUsersString,
    searchUsersString,
    isAllLoaded,
  } = useBoundStore((state) => state.usersSlice);

  const searchByList = [
    { label: 'Email', value: 'Email' },
    { label: 'Name', value: 'Name' },
  ];
  const sortDirectionList = [
    { label: 'Most Recent', value: 'desc' },
    { label: 'Oldest', value: 'asc' },
  ];

  const kycNames = {
    null: 'Incomplete',
    init: 'Initialized',
    pending: 'Pending',
    prechecked: 'Pre Checked',
    queued: 'Queued',
    completed: 'Completed',
    onHold: 'On Hold',
    tokenGenerated: 'Token Generated',
  };

  const kycColors = {
    tokenGenerated: 'bg-[#f8f8d2]',
    completed: 'bg-[#d2f8d2]',
    null: 'bg-[#ff9794]',
  };

  const handleFetchSearchedUsers = async () => {
    try {
      const searchUsersInput = {
        search_string: searchUsersString,
        search_by: searchBy.toLowerCase(),
        sort_by: sortBy,
        sort_direction: sortDirectionList[sortDirectionIndex].value,
        owns_nft: selectedAsset !== null ? assets[selectedAsset].batch_id : '',
      };
      console.log({ searchUsersInput });
      await fetchSearchedUsers(searchUsersInput, pageNumber);
    } catch (fetchSearchedUsersErr) {
      console.log({ fetchSearchedUsersErr });
    }
  };

  const usersData = useMemo(() => {
    return {
      columnNames: ['Name', 'Email', 'Created At', 'KYC'],
      data: searchResults?.map((item) => [
        {
          value: (
            <div className="flex">
              <img
                className="mr-[15px] max-h-[26px] min-h-[26px] min-w-[26px] max-w-[26px] rounded-[13px] "
                src={item.photo || DefaultProfileImage}
              />
              <p> {item.name} </p>
            </div>
          ),
          className: 'text-md ',
        },
        {
          value: item.email,
          className: 'text-md',
        },
        {
          value: spacetime(item?.created_at).format('numeric-us'),
          className: 'text-md ',
        },
        {
          value: (
            <button
              className={cn(
                'w-full rounded-[5px] border py-[2px] ',
                kycColors[`${item?.kyc_status}`],
              )}
            >
              {kycNames[`${item?.kyc_status}`]}
            </button>
          ),
          className: 'text-md',
        },
      ]),
    };
  }, [searchResults, isLoading]);

  const handleSortByList = useMemo(() => {
    if (sortBy == 'name' || sortBy == 'email') {
      return [
        { title: 'Z-A', value: 'desc' },
        { title: 'A-Z', value: 'asc' },
      ];
    } else {
      return [
        { title: 'Most Recent', value: 'desc' },
        { title: 'Oldest', value: 'asc' },
      ];
    }
  }, [sortBy]);

  const handleExportUsers = async () => {
    try {
      const searchUsersInput = {
        search_string: searchUsersString,
        search_by: searchBy.toLowerCase(),
        sort_by: sortBy,
        sort_direction: sortDirectionList[sortDirectionIndex].value,
        owns_nft: selectedAsset !== null ? assets[selectedAsset].batch_id : '',
      };
      console.log({ searchUsersInput });
      const {
        data: { exportUsers: exportUsersRes },
      } = await client.query({
        query: ExportUsersDocument,
        variables: {
          input: searchUsersInput,
          fields: [
            'user_id',
            'kyc_passed',
            'username',
            'name',
            'email',
            'phone_number',
            'photo',
            'created_at',
            'updated_at',
            'project_balance',
          ],
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ exportUsersRes });
      toast({
        title: 'Your CSV is Being Generated',
        variant: 'default',
        description: `A list of your users will be downloaded once it completes.`,
      });
    } catch (exportUsersErr) {
      console.log({ exportUsersErr });
    }
  };

  const handleGetTableCountsRes = async () => {
    const {
      data: { getCounts: getCountsRes },
    } = await client.query({
      query: GetCountsDocument,
      context: {
        clientName: ClientName.Marketplace,
      },
    });
    setUserCount(getCountsRes.users);
  };

  useEffect(() => {
    if ((searchUsersString.length > 0 || filterSelected) && !firstRender) {
      handleFetchSearchedUsers();
    }
    setFirstRender(false);
  }, [searchBy, sortDirectionIndex, sortBy, pageNumber, selectedAsset]);

  useEffect(() => {
    if (searchUsersString.length == 0) {
      handleFetchSearchedUsers();
      setFilterSelected(false);
    }
  }, [searchUsersString]);

  useEffect(() => {
    handleGetTableCountsRes();
  }, []);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      <div className="relative flex flex-col gap-[20px]">
        <SectionDivider
          titles={[`Investors ${userCount !== false ? `(${userCount})` : ''}`]}
          img={<Team stroke="white" />}
          color="#ff6661"
        />
        <div className="flex">
          <SearchInput
            value={searchUsersString}
            onChange={(text) => {
              console.log(text);
              setPageNumber(0);
              dispatchSetSearchUsersString(text);
            }}
            onSubmit={() => {
              console.log('SUBMITTED');
              handleFetchSearchedUsers();
            }}
          />
          <div className="ml-[15px] flex gap-[15px]">
            <div className="relative">
              <p className="absolute left-0 top-0 z-10 ml-[10px] mt-[-13px] px-[5px] text-xs opacity-60">
                Search By
              </p>
              <SelectorDropdown
                className="h-[32px] w-[100px] rounded-[20px]"
                control={false}
                options={searchByList}
                selected={searchBy}
                onSelect={(item, index) => {
                  console.log(item);
                  setPageNumber(0);
                  setSearchBy(item);
                  setFilterSelected(true);
                }}
              />
            </div>
            <button
              className="relative flex h-[32px] w-[130px] items-center justify-center rounded-[20px] border bg-card"
              onClick={() => {
                setPageNumber(0);
                setSortDirectionIndex(sortDirectionIndex == 0 ? 1 : 0);
                setFilterSelected(true);
              }}
            >
              <Sort />
              <p className="absolute left-0 top-0 z-10 ml-[10px] mt-[-14px] px-[5px] text-xs opacity-60">
                Sort By
              </p>
              <p className="ml-[5px] text-sm"> {handleSortByList[sortDirectionIndex].title} </p>
            </button>
            <button
              className="relative flex h-[32px] w-[70px] items-center justify-center rounded-[20px] border bg-card"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <p className="absolute left-0 top-0 z-10 ml-[10px] mt-[-14px] px-[5px] text-xs opacity-60">
                Asset
              </p>
              {selectedAsset ? (
                <img
                  className="size-[32px] rounded-[5px] object-contain"
                  src={assets[selectedAsset].media_ipfs_link}
                />
              ) : (
                <MintWizard className="stroke-[#808080]" />
              )}
            </button>
            {selectedAsset && (
              <button
                className="mb-[5px] flex items-center"
                onClick={() => {
                  setSelectedAsset(null);
                }}
              >
                <Cross1Icon />
              </button>
            )}
          </div>
          <button className="ml-auto" onClick={handleExportUsers}>
            <DownloadIcon className="size-[25px]" />
          </button>
        </div>
        <div className="rounded-[10px] border bg-card pb-[20px] pr-[10px] pt-[10px] shadow-md">
          <InfiniteScroll
            className="contentScroll"
            dataLength={usersData.data.length}
            next={() => {
              console.log('NEXT FIRED');
              setPageNumber(pageNumber + 1);
              setFilterSelected(true);
            }}
            hasMore={!isAllLoaded && usersData.data.length > 0}
            height={'calc(100vh - 280px)'}
            loader={
              <div className="mt-[10px]">
                <div className="m-auto size-8 animate-spin rounded-full border-t-2 border-primary" />
              </div>
            }
          >
            <Table
              ariaLabel="users-table"
              columnNames={usersData.columnNames}
              data={usersData.data}
              isLoading={isLoading}
              onRowClick={(item, index) => {
                navigate(`/user-information/${searchResults[index].number}`);
              }}
            />
          </InfiniteScroll>
        </div>
      </div>
      <SelectAsset
        open={modalOpen}
        setOpen={setModalOpen}
        setSelectedAsset={(i) => {
          setSelectedAsset(i);
          setFilterSelected(true);
          setModalOpen(false);
        }}
        selectedAsset={selectedAsset}
      />
    </div>
  );
};

export default Users;
