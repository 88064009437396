import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import spacetime from 'spacetime';

import Confetti from '@/assets/Confetti.svg?react';
import Contract from '@/assets/Contracts.svg?react';
import { BackNext } from '@/components/ui/back-next';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';

import CongratsModal from '../modals/congrats-modal';

// Helper function to get circle classes based on the step status
const getCircleClass = (status, color) => {
  if (status === 'Completed') {
    return `bg-${color} flex items-center justify-center border-[3px] border-primary `;
  } else if (status === 'In Progress') {
    return `border-[3px] border-${color} flex items-center justify-center border-primary`;
  } else {
    return `bg-gray-300`;
  }
};

// Helper function to get line classes based on the step status
const getLineClass = (status, color) => {
  if (status === 'Completed') {
    return `mx-[20px] h-[4px] w-full bg-primary`;
  } else if (status === 'In Progress') {
    return 'mx-[20px] h-[4px] w-full bg-text-color opacity-40';
  } else {
    return 'mx-[20px] h-[4px] w-full bg-text-color opacity-40';
  }
};

// Helper function to get status text color
const getStatusTextClass = (status) => {
  if (status === 'Completed') {
    return 'text-green-500';
  } else if (status === 'In Progress') {
    return 'text-blue-500';
  } else {
    return 'text-gray-400';
  }
};

function SecurityTokenView() {
  const methods = useForm({
    defaultValues: {
      max_us_investor_percentage: [0],
    },
  });
  const [currentLevel, setCurrentLevel] = useState(0);
  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  const { addSecurityToken } = useBoundStore((state) => state.securityTokenSlice);

  const { handleSubmit } = methods;
  const formValues = methods.watch();

  const calculateLevel = (levelForComplete = 0) => {
    if (levelForComplete === currentLevel) {
      return 'In Progress';
    } else if (levelForComplete > currentLevel) {
      return 'Pending';
    } else {
      return 'Completed';
    }
  };

  const steps = [
    //    status === 'In Progress' 'Completed'
    {
      label: 'Create Token',
      status: calculateLevel(0),
      color: 'green-500',
    },
    {
      label: 'Cusomize Token',
      status: calculateLevel(1),
      color: 'blue-500',
    },
    {
      label: 'Review & Deploy',
      status: calculateLevel(2),
      color: 'gray-300',
    },
  ];

  const tokenDetails = [
    {
      inputs: [
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter the name of the token offering',
          requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Token Image',
          state: 'token_image',
          placeholder: 'Enter the company name',
          requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Token Icon',
          state: 'token_icon',
          placeholder: 'Enter the company name',
          requiredForNextStep: true,
        },
      ],
    },
  ];

  const createTokenInputs = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Name of the Token Offering',
          state: 'name',
          placeholder: 'Enter the name of the token offering',
        },
        {
          type: 'currencyInput',
          label: 'Amount of the Raise',
          state: 'amount',
          placeholder: 'Enter the total amount to be raised',
        },
        {
          type: 'currencyInput',
          label: 'Minimum Investment by an Investor',
          state: 'min_investment_usd',
          placeholder: 'Enter the minimum investment amount',
          halfWidth: true,
        },
        {
          type: 'currencyInput',
          label: 'Maximum Investment by an Investor',
          state: 'max_investment_usd',
          placeholder: 'Enter the maximum investment amount',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Number of Investors Allowed Worldwide',
          state: 'max_investors_worldwide',
          placeholder: 'Enter the maximum number of investors worldwide',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Non-accredited Investors',
          state: 'max_non_accredited_investors',
          placeholder: 'Enter the limit for non-accredited investors',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Accredited Investors',
          state: 'max_accredited_investors',
          placeholder: 'Enter the limit for accredited investors',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Number of US Investors',
          state: 'max_us_investors',
          placeholder: 'Enter the limit for US investors',
          halfWidth: true,
        },
        {
          type: 'percentSlider',
          label: 'Max Percentage of US Investors',
          state: 'max_us_investor_percentage',
          placeholder: 'Select the percentage of total holders',
        },
        {
          type: 'calendar',
          label: 'Lockout period for US Investors (months)',
          state: 'holding_period_days_us',
          placeholder: 'Enter the time period until security becomes tradeable',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'Lockout period for Non US Investors (months)',
          state: 'holding_period_days_non_us',
          placeholder: 'Enter the timeframe for secondary market trading',
          halfWidth: true,
        },
        {
          type: 'dropdown',
          label: 'Regulation Type',
          state: 'offering_type',
          placeholder: 'Select the applicable regulation (e.g., Reg D, A, S, CF, Non-regulated)',
          options: [
            { label: 'Accrued', value: 'Accrued' },
            { label: 'Monthly', value: 'Monthly' },
            { label: 'Quarterly', value: 'Quarterly' },
            { label: 'Annually', value: 'Annually' },
          ], // Assuming options array exists
          halfWidth: true,
        },
        {
          type: 'dropdown',
          label: 'Accreditation Process',
          state: 'accreditation_process',
          placeholder: 'Enter the type of investor',
          options: [
            { label: 'Self Certified', value: 'Self Certified' },
            {
              label: 'Third Party Verification Required',
              value: 'Third Party Verification Required',
            },
          ], // Assuming options array exists
          halfWidth: true,
        },

        {
          type: 'calendar',
          label: 'When Does the Offer Start?',
          state: 'start_date',
          placeholder: 'Select the offer start date',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'When Does the Offer End?',
          state: 'closing_date',
          placeholder: 'Select the offer end date',
          halfWidth: true,
        },
      ],
    },
  ];

  const mockData = {
    icon_url: formValues?.token_icon?.hostedUrl,
    title: formValues?.name,
    description: formValues?.description,
    img: formValues?.token_image?.hostedUrl,
    raisedObj: {
      investmentGoal: formValues?.amount,
      minInvestment: formValues?.min_investment_usd,
      investmentFinalDate: `${spacetime(formValues?.closing_date).format('{day}, {month} {date-ordinal}, {year}')}`,
    },
  };

  const securityToken = {
    max_supply: formValues?.amount,
    kyc_required: true,
  };

  const progressRaised = 0; // Assuming this is calculated elsewhere

  const mockFormData = [
    {
      title: 'Form 1',
      content: [
        { label: 'Funding Goal', value: formValues?.amount, link: '' },
        { label: 'Security Type', value: formValues?.security_type, link: ' ' },
        { label: 'Min Investment', value: formValues?.min_investment_usd, link: '' },

        { label: 'Max Investment', value: formValues?.max_investment_usd, link: '' },
        {
          label: 'Closing Date',
          value: `${spacetime(formValues?.closing_date).format('{day}, {month} {date-ordinal}, {year}')}`,
          link: '',
        },
        { label: 'Offering Type', value: formValues?.offering_type, link: '' },
      ],
    },
  ];

  const handleContinue = () => {
    if (currentLevel == 2) {
      addSecurityToken(formValues);
      setCongratsModalOpen(!congratsModalOpen);
      return;
    }
    setCurrentLevel(currentLevel + 1);
  };

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  return (
    <div className="relative mx-[0px] flex size-full max-w-[1200px] flex-col items-center gap-[50px]">
      <div className="w-full">
        <div className="flex w-full items-center justify-between px-[50px]">
          {steps.map((step, index) => (
            <div
              key={index}
              className={cn(
                'flex w-full flex-col items-center',
                index == steps.length - 1 && 'max-w-[45px]',
              )}
            >
              <div className="flex w-full items-center">
                <div
                  className={cn(
                    ' h-[44px] w-[44px] min-w-[44px] rounded-full',
                    getCircleClass(step.status, step.color),
                  )}
                >
                  {step.status === 'Completed' && (
                    <svg
                      className="size-[32px] stroke-primary"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                  )}
                  {step.status === 'In Progress' && (
                    <div className="size-[32px] rounded-full bg-primary"></div>
                  )}
                </div>
                {index !== steps.length - 1 && (
                  <div
                    className={getLineClass(step.status, step.color)}
                    // className="h-[4px] w-full bg-text-color opacity-40"
                  ></div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="flex w-full justify-between">
          {steps.map((step, index) => (
            <div key={step.label} className="flex w-[150px] flex-col items-center">
              <p className="mt-[16px] text-lg font-semibold">{step.label}</p>
              <p className={cn('', getStatusTextClass(step.status))}>{step.status}</p>
            </div>
          ))}
        </div>
      </div>
      <form className="w-full max-w-[1000px]" onSubmit={handleSubmit(handleContinue)}>
        {currentLevel == 0 && (
          <>
            <h1 className="mb-[30px] w-full text-2xl font-semibold">Create Your Token</h1>
            <WrappedInputSection methods={methods} data={createTokenInputs} />
          </>
        )}

        {currentLevel == 1 && (
          <>
            <h1 className="mb-[30px] w-full text-2xl font-semibold">Cusomize Your Token</h1>
            <div className="flex w-full gap-[30px] ">
              <div className="w-full ">
                <WrappedInputSection
                  methods={methods}
                  data={[{ inputs: tokenDetails[0].inputs.slice(0, 2) }]}
                />
              </div>
              <div className="w-[400px] ">
                <WrappedInputSection
                  methods={methods}
                  data={[{ inputs: tokenDetails[0].inputs.slice(2, 3) }]}
                />
              </div>
            </div>
          </>
        )}
        {currentLevel == 2 && (
          <>
            <h1 className="mb-[30px] w-full text-2xl font-semibold">Review & Deploy</h1>
            <div className="mb-[16px] flex">
              <img
                src={mockData.icon_url}
                className="mr-[16px] size-[72px] rounded-[10px] border border-text-color"
              />
              <div className="flex flex-col">
                <h1 className="mb-[8px] text-[32px] font-[400]">{mockData.title}</h1>
                <p className="text-[14px]">{mockData.description}</p>
              </div>
            </div>
            <div className="mb-[60px] flex flex-col sm:flex-row">
              <img
                src={mockData.img}
                className="mr-[32px] h-[375px] w-full rounded-[25px] sm:mr-[10px] sm:w-full"
                style={{ objectFit: 'cover' }}
              />
              <div
                className="bg-card-color h-[375px] min-w-[400px] max-w-[400px] rounded-[16px] p-[24px] 
          shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] "
              >
                <div className="mb-[12px] flex justify-between">
                  <p className="text-[14px] font-[300]">Raising</p>
                  <div className="rounded-[15px] bg-gray-300 p-[0px_12px]">
                    <p className="font-[500]">${securityToken?.max_supply}</p>
                  </div>
                </div>
                <h1 className="mb-[12px] text-[28px]">
                  $0
                  <span className="ml-[10px] text-[18px]">Raised</span>
                </h1>
                <div className="mb-[16px] flex">
                  <div className="h-[16px] w-full overflow-hidden rounded-[10px] bg-gray-300">
                    <div
                      style={{ width: `${progressRaised}%` }}
                      className="h-[16px] rounded-[10px] bg-primary"
                    />
                  </div>
                  <p className="ml-[10px] text-[14px]">{progressRaised}%</p>
                </div>
                <div className="py-[16px]">
                  <p className="text-[16px] font-[300]">
                    <span className="text-[22px] font-[400]">
                      {mockData.raisedObj.investmentFinalDate}
                    </span>
                  </p>
                </div>
                <button
                  className="h-[50px] w-full rounded-[100px] bg-primary text-text-color opacity-60"
                  onClick={() => {}}
                >
                  Invest Now
                </button>
                <p className="mt-[7px] text-center text-[14px] font-[300]">
                  <strong>{`$${mockData.raisedObj.minInvestment?.toLocaleString()}.00`}</strong>{' '}
                  min. investment
                </p>
              </div>
            </div>
            <div className="mb-[80px] flex flex-col justify-between sm:flex-row">
              {mockFormData.map(({ title, content }, index) => (
                <div
                  className="mb-[20px] w-full rounded-[16px] border border-text-color p-[24px] sm:mb-0 sm:w-[59%]"
                  key={`form_display_${index}`}
                >
                  <h5 className="border-b border-text-color pb-[16px]">{title}</h5>
                  <div className="mt-[16px] flex flex-wrap gap-[10px] ">
                    {content
                      ?.filter((item) => item.value)
                      ?.map(({ label, value, link }, index2) => (
                        <div key={`keyVal_${index2}`} className="min-w-[32%]">
                          <label className="text-[13px]">{label}</label>
                          {link?.length ? (
                            <a
                              href={`${link}`}
                              target="_blank"
                              className="link flex text-[16px] text-[#369EDA] underline"
                              rel="noreferrer"
                            >
                              <Contract className="mr-[5px]" /> {value}
                            </a>
                          ) : (
                            <p className="text-[16px]">
                              {link && <Contract />} {value}
                            </p>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <div className="mt-[30px]">
          <BackNext
            numOfLevels={3}
            finalBtnText={'Approve & Deploy'}
            currentLevel={currentLevel}
            setCurrentLevel={setCurrentLevel}
            finalBtnClick={() => {}}
          />
        </div>
      </form>
      <CongratsModal
        title="Congratulations"
        subTitle="You're all set! Your security token is now active."
        open={congratsModalOpen}
        setOpen={() => {
          setCongratsModalOpen(!congratsModalOpen);
        }}
        onConfirm={() => {}}
      />
    </div>
  );
}

export default SecurityTokenView;
