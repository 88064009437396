import { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';

import AllRealEstate from '@/components/pages/all-real-estate';
import Rewards from '@/components/pages/all-rewards.tsx';
import Utilities from '@/components/pages/all-utilities.tsx';
import Analytics from '@/components/pages/analytics';
import AssetHome from '@/components/pages/asset-home';
import AssetView from '@/components/pages/asset-view.tsx';
import BusinessDetails from '@/components/pages/business-details.tsx';
import CapTable from '@/components/pages/cap-table';
import CapitalRaise from '@/components/pages/capital-raise.tsx';
import CollectionView from '@/components/pages/collection-view.tsx';
import BusinessHome from '@/components/pages/company-home.tsx';
import ComplianceChecklist from '@/components/pages/compliance-checklist.tsx';
import CreateAsset from '@/components/pages/create-asset';
import CreateCollection from '@/components/pages/create-collection';
import CreateBusiness from '@/components/pages/create-company.tsx';
import CreateList from '@/components/pages/create-list.tsx';
import CreateRewards from '@/components/pages/create-rewards.tsx';
import CreateToken from '@/components/pages/create-token.tsx';
import CreateUtility from '@/components/pages/create-utility.tsx';
import Developer from '@/components/pages/developer-view.tsx';
import ExchangeChecklist from '@/components/pages/exchange-checklist.tsx';
import FolderView from '@/components/pages/folder-view.tsx';
import Fundraise from '@/components/pages/fundraise';
import Home from '@/components/pages/home';
import AltHome from '@/components/pages/home-alt';
import MyContent from '@/components/pages/my-content.tsx';
import MyDocuments from '@/components/pages/my-documents.tsx';
import PastRaises from '@/components/pages/past-raises.tsx';
import RewardsView from '@/components/pages/rewards-view.tsx';
import SecurityTokenView from '@/components/pages/security-token-view.tsx';
import SelectUtil from '@/components/pages/select-utility.tsx';
import Settings from '@/components/pages/settings';
import Signin from '@/components/pages/signin';
import TeamDetails from '@/components/pages/team-details.tsx';
import Test from '@/components/pages/test.tsx';
import UseOfFunds from '@/components/pages/use-of-funds.tsx';
import UserView from '@/components/pages/user-view.tsx';
import Users from '@/components/pages/users.tsx';
import UtilitiyView from '@/components/pages/utility-view.tsx';
import { client, getApolloClient } from '@/lib/apollo';
import { ApolloProvider } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

import AuthorizedLayout from './components/layouts/authorized-layout';
import SimpleLayout from './components/layouts/simple-layout';
import AllPayoutPrograms from './components/pages/all-payout-programs.tsx';
import BrokerDealerChecklist from './components/pages/broker-dealer-checklist.tsx';
import CreateRealEstate from './components/pages/create-real-estate.tsx';
import Financials from './components/pages/financials.tsx';
import GeneralInfo from './components/pages/general-info.tsx';
import MySite from './components/pages/my-site.tsx';
import PayoutProgram from './components/pages/payout-program.tsx';
import ViewRealEstate from './components/pages/real-estate-view.tsx';
import SiteBuilder from './components/pages/site-builder.tsx';
import TransferAgentChecklist from './components/pages/transfer-agent-checklist.tsx';

getApolloClient();

if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  console.debug('load apollo client messages for dev env');
  loadDevMessages();
  loadErrorMessages();
}

function App() {
  return (
    // <ApolloProvider client={client}>
    <BrowserRouter>
      <Routes>
        <Route path="" element={<SimpleLayout />}>
          <Route path="signin" element={<Signin />} />
          <Route path="reset-password" element={<Signin />} />
          {/* Nov 29 -> the new flow, no sign up */}
          {/* <Route path="signup" element={<Signup />} /> */}
          <Route path="*" element={<div>Not found</div>} />

          {/* protected routes */}
          <Route path="" element={<AuthorizedLayout />}>
            <Route path="security-token-view" element={<SecurityTokenView />} />
            <Route path="cap-table" element={<CapTable />} />

            <Route path="fundraise/:id" element={<Fundraise />} />
            <Route path="compliance-checklist" element={<ComplianceChecklist />} />
            <Route path="exchange-checklist" element={<ExchangeChecklist />} />

            <Route path="site-builder/:id" element={<SiteBuilder />} />
            <Route path="transfer-agent-checklist" element={<TransferAgentChecklist />} />
            <Route path="broker-dealer-checklist" element={<BrokerDealerChecklist />} />
            <Route path="business-details" element={<BusinessDetails />} />
            <Route path="past-raises" element={<PastRaises />} />
            <Route path="capital-raise" element={<CapitalRaise />} />
            <Route path="general-information" element={<GeneralInfo />} />
            <Route path="financials" element={<Financials />} />
            <Route path="team-details" element={<TeamDetails />} />
            <Route path="use-of-funds" element={<UseOfFunds />} />
            <Route path="my-documents" element={<MyDocuments />} />

            <Route path="company-home" element={<BusinessHome />} />
            <Route path="create-company" element={<CreateBusiness />} />
            <Route path="create-token" element={<CreateToken />} />

            <Route path="home" element={<AltHome />} />

            {/* NEW ROUTES */}

            <Route path="payout-program" element={<PayoutProgram />} />
            <Route path="edit-payout-program/:id" element={<PayoutProgram />} />

            {/* <Route path="all-payout-programs" element={<AllPayoutPrograms />} /> */}
            <Route path="nft-site" element={<MySite />} />

            <Route path="business-account" element={<Signin />} />
            <Route path="home" element={<Home />} />

            <Route path="asset-home" element={<AssetHome />} />
            <Route path="all-real-estate" element={<AllRealEstate />} />

            <Route path="utilities" element={<Utilities />} />
            <Route path="select-utility" element={<SelectUtil />} />
            <Route path="utility-view/:id" element={<UtilitiyView />} />

            <Route path="create-collection" element={<CreateCollection />} />
            <Route path="edit-collection/:id" element={<CreateCollection />} />

            <Route path="create-asset" element={<CreateAsset />} />
            <Route path="edit-asset/:id" element={<CreateAsset />} />

            <Route path="collection/:id" element={<CollectionView />} />
            <Route path="asset/:id" element={<AssetView />} />

            <Route path="create-exclusive-video" element={<CreateUtility />} />
            <Route path="users" element={<Users />} />
            <Route path="user-information/:id" element={<UserView />} />

            <Route path="developer" element={<Developer />} />
            <Route path="settings" element={<Settings />} />
            <Route path="analytics" element={<Analytics />} />

            <Route path="create-list" element={<CreateList />} />
            <Route path="rewards" element={<Rewards />} />
            <Route path="create-rewards" element={<CreateRewards />} />
            <Route path="rewards-view/:id" element={<RewardsView />} />

            <Route path="my-content" element={<MyContent />} />
            <Route path="test" element={<Test />} />
            <Route path="folder/*" element={<FolderView />} />

            <Route path="create-real-estate" element={<CreateRealEstate />} />
            <Route path="edit-real-estate/:id" element={<CreateRealEstate />} />
            <Route path="real-estate/:id" element={<ViewRealEstate />} />

            <Route path="*" element={<div>Not found</div>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
    // </ApolloProvider>
  );
}

export default App;
