import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { cn } from '@/lib/utils';

import LocationPin from '../../assets/location-pin.svg?react';

function CompanyCard({ item, index, loading = true }) {
  const navigate = useNavigate();

  return (
    <button
      key={`card_${index}`}
      className="grid h-[205px] w-[420px] rounded-[16px] bg-card p-[22px] shadow-md transition-shadow duration-500 ease-in-out hover:shadow-lg"
      onClick={() => {
        // navigate(`/security-token-view`);
      }}
    >
      <div className="flex">
        <img
          src={!loading && item?.token_icon?.hostedUrl}
          className={cn(
            'size-[6rem] min-w-[6rem] rounded-[16px] bg-secondary object-cover',
            loading ? 'animate-pulse' : '',
          )}
        />
        <div className="relative ml-[18px] w-full">
          <h1
            className={cn(
              'relative line-clamp-2 text-left text-lg font-semibold',
              loading ? 'h-[28px] w-[100px] animate-pulse rounded-[6px]' : '',
            )}
          >
            {!loading && item.name}
          </h1>
          <div className="absolute bottom-0 left-0 mb-[0px] flex w-full ">
            <h5
              className={cn(
                ` w-[88px] rounded-[4px] bg-[#6b8cef33] py-[4px] text-center text-xs font-semibold capitalize`,
                loading ? 'h-[24px] animate-pulse rounded-[6px]' : '',
              )}
            >
              {!loading && 'Live'}
            </h5>
            {/* <p className="ml-[20px] font-medium">| </p>
            <p
              className={cn(
                'ml-[20px] font-normal ',
                loading ? 'h-[24px] w-[80px] animate-pulse rounded-[6px]' : '',
              )}
            >
              {item.offering_type}
            </p> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[14px] pt-[20px] ">
        <p className={cn('flex items-center ')}>
          {/* <LocationPin className="mr-[10px] opacity-60" /> */}
          <p
            className={
              loading ? ' h-[24px] w-[80%] animate-pulse rounded-[6px]' : 'line-clamp-1 opacity-60'
            }
          >
            {!loading && item.description}
          </p>
        </p>
      </div>
    </button>
  );
}

export default CompanyCard;
