import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';

const BackNext: FunctionComponent<any> = ({
  currentLevel = 0,
  setCurrentLevel = () => {},
  numOfLevels = 3,
  finalBtnText = 'Finish',
  finalBtnClick = () => {},
  disabled = false,
  onBack = () => {},
  onNext = () => {},
  loading = false,
}) => {
  const navigate = useNavigate();

  // TODO: Modify these buttons in such a way that
  //       when the user clicks them they are sent
  //       back to the top of the page

  return (
    <div className="mb-[30px] mt-auto flex w-full justify-between">
      <button
        className="hover:bg-lightgrey h-[48px] w-[200px] rounded-md border bg-card py-[10px] text-base uppercase text-primary"
        type="button"
        onClick={() => {
          onBack();
          if (currentLevel == 0) {
            navigate(-1);
          } else {
            setCurrentLevel(currentLevel - 1);
          }
        }}
      >
        Back
      </button>
      <Button
        className="h-[48px] w-[200px] border py-[10px] text-base"
        type="submit"
        onClick={() => {
          if (currentLevel == numOfLevels - 1) {
            finalBtnClick();
          }
        }}
      >
        {loading ? (
          <>
            <div className="size-5 animate-spin rounded-full border-t-2 border-card" />
          </>
        ) : currentLevel == numOfLevels - 1 ? (
          finalBtnText
        ) : (
          'Next'
        )}
      </Button>
    </div>
  );
};

export { BackNext };
