import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import spacetime from 'spacetime';

import Brush from '@/assets/Brush.svg?react';
import DefaultProfileImage from '@/assets/Default_profile_bold.svg';
import HomeImg from '@/assets/Home.svg?react';
import ListAlt from '@/assets/ListAlt.svg?react';
import MintWizard from '@/assets/Mintwizard.svg?react';
import Users from '@/assets/Users.svg?react';
import MovingGradientButton from '@/components/ui/moving-gradient-button';
import SectionDivider from '@/components/ui/section-divider';
import { GetTransactionHistoryDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';

import Table from '../ui/table';

const HomeAlt: FunctionComponent<any> = () => {
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const assets = useBoundStore((state) => state.assetsSlice.assets);
  const assetsInitialLoad = useBoundStore((state) => state.assetsSlice.initialLoad);
  const { fetchSearchedUsers, searchUsersString, searchResults, isLoading } = useBoundStore(
    (state) => state.usersSlice,
  );
  const { addSecurityToken, securityTokens } = useBoundStore((state) => state.securityTokenSlice);

  const [transHistory, setTransHistory] = useState([]);

  const navigate = useNavigate();

  const assetsWithLoad = useMemo(() => {
    if (assetsInitialLoad) {
      return [
        { loading: true },
        { loading: true },
        { loading: true },
        { loading: true },
        { loading: true },
        { loading: true },
        { loading: true },
        { loading: true },
      ];
    } else {
      return assets;
    }
  }, [assets, assetsInitialLoad]);

  const handleFetchSearchedUsers = async () => {
    try {
      const searchUsersInput = {
        search_string: searchUsersString,
        sort_by: 'created_at',
      };
      console.log({ searchUsersInput });
      await fetchSearchedUsers(searchUsersInput, 0);
    } catch (fetchSearchedUsersErr) {
      console.log({ fetchSearchedUsersErr });
    }
  };

  const handleGetTransactionHistory = async () => {
    try {
      const {
        data: { getTransactionHistory: getTransactionHistoryRes },
      } = await client.query({
        query: GetTransactionHistoryDocument,
        variables: { input: { page_number: 0, batch_size: 5 } },
      });
      console.log({ getTransactionHistoryRes });
      setTransHistory(getTransactionHistoryRes);
    } catch (fetchSearchedUsersErr) {
      console.log({ fetchSearchedUsersErr });
    }
  };

  const transactionsData = useMemo(() => {
    return {
      columnNames: ['Event', 'Price', 'Created At'],
      data: transHistory?.map((item) => [
        {
          value: item.primary_event?.event_type.includes('Mint')
            ? 'Purchase'
            : item.primary_event?.event_type,
          className: 'text-md capitalize max-w-[100px] truncate',
        },
        {
          value: `$${item?.primary_event?.ui_price ?? 0}`,
          className: 'text-md',
        },
        {
          value: spacetime(item?.receipt.block_timestamp / 1).unixFmt('MMM. dd - hh:mm a'),
          className: 'text-md ',
        },
      ]),
    };
  }, [transHistory]);

  const usersData = useMemo(() => {
    return {
      columnNames: ['Name', 'Created At'],
      data: searchResults
        ?.map((item) => [
          {
            value: (
              <div className="flex">
                <img
                  className="mr-[15px] max-h-[26px] min-h-[26px] min-w-[26px] max-w-[26px] rounded-[13px] "
                  src={item.photo || DefaultProfileImage}
                />
                <p> {item.name} </p>
              </div>
            ),
            className: 'text-md ',
          },
          {
            value: spacetime(item?.created_at).format('numeric-us'),
            className: 'text-md ',
          },
        ])
        .slice(0, 5),
    };
  }, [searchResults, isLoading]);

  useEffect(() => {
    handleFetchSearchedUsers();
    handleGetTransactionHistory();
  }, []);

  return (
    <div className="grid w-full max-w-[1300px] flex-col ">
      <div className="mx-auto flex w-full max-w-[1100px] flex-col gap-[0px] space-y-[20px] p-[0px]">
        <SectionDivider
          color={'#9256FF'}
          titles={['Home']}
          img={<HomeImg className="stroke-white" />}
        />
        <div>
          <a
            href={selectedProject?.react_urls}
            target="_blank"
            className="  rounded-full border-2 border-primary p-[3px] px-[10px] text-sm font-semibold text-primary "
            rel="noreferrer"
          >
            Open My Marketplace
          </a>
        </div>
        <div className="flex space-x-[20px]">
          <div className="flex h-[345px] w-[60%] flex-col rounded-[10px] border bg-card  p-[16px] shadow-md">
            <SectionDivider
              color={'#9256FF'}
              titles={['Security Tokens']}
              img={<MintWizard className="mr-[12px] size-[22px] stroke-text-color" />}
              otherIcon
              rightText="See More"
              rightTextNavigateTo="/asset-home"
            />
            <div className=" grid grow grid-cols-5 flex-wrap justify-center gap-[10px] p-[10px] pt-[12px]">
              {securityTokens.slice(0, 10).map((nft, index) => (
                <button
                  key={nft}
                  className="mx-auto flex max-h-[120px] w-full flex-col rounded-[10px] border p-[4px] shadow-md transition-all duration-300 ease-in-out hover:-translate-y-[4px] hover:shadow-lg"
                  onClick={() => {
                    // navigate(`/asset/${nft.batch_id}`);
                  }}
                >
                  <img
                    src={nft?.token_icon?.hostedUrl}
                    className={cn(
                      'size-[90px] w-full rounded-[10px] border object-cover shadow-md',
                      nft.loading ? 'animate-pulse' : '',
                    )}
                  />
                  <p className="mt-[3px] line-clamp-1 max-w-full overflow-hidden  truncate whitespace-nowrap text-center text-sm font-normal">
                    {nft.name}
                  </p>
                </button>
              ))}
            </div>
          </div>
          <div className="h-[345px] w-[40%] rounded-[10px] border bg-card  py-[16px] shadow-md">
            <div className="px-[16px]">
              <SectionDivider
                color={'#9256FF'}
                titles={['Transactions']}
                img={<ListAlt className="mr-[12px] size-[20px] stroke-text-color" />}
                otherIcon
                rightText="See More"
                rightTextNavigateTo="/analytics"
              />
            </div>
            <Table
              ariaLabel="transactions-table"
              columnNames={transactionsData.columnNames}
              data={transactionsData.data}
              isLoading={false}
              onRowClick={(item, index) => {}}
            />
          </div>
        </div>
        <div className="flex gap-[0px]">
          <div className="mr-[20px] h-[322px] w-[40%] rounded-[10px] border bg-card  py-[16px] shadow-md">
            <div className="relative px-[16px]">
              <SectionDivider
                color={'#9256FF'}
                titles={['Investors']}
                img={<Users className="mr-[12px] size-[22px] stroke-text-color" />}
                otherIcon
                rightText="See More"
                rightTextNavigateTo="/users"
              />
            </div>
            <Table
              ariaLabel="users-table"
              columnNames={usersData.columnNames}
              data={usersData.data}
              isLoading={isLoading}
              onRowClick={(item, index) => {
                console.log({ item: assets[index] });
                navigate(`/user-information/${searchResults[index].number}`);
              }}
            />
          </div>
          <MovingGradientButton
            className="h-[320px] w-[60%]"
            backgroundImage="https://imagedelivery.net/VXSUG--97rEGVHj8SzZmow/f2724e5f-77a4-4f76-92b6-cc33c4aad400/public"
            onClick={() => {
              navigate(`/nft-site`);
            }}
          >
            <Brush className="mr-[15px] mt-[-5px] size-[50px]" /> Customize Offering Page
          </MovingGradientButton>
        </div>
      </div>
    </div>
  );
};

export default HomeAlt;
